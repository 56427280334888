import React from "react";
import { ConversationalForm } from "conversational-form";

export default class MyForm extends React.Component {
    constructor(props) {
        super(props);
        this.formFields = [
            {
                tag: "select",
                name: "problem-type",
                "cf-questions": "What can I help you with?",
                children: [
                    {
                        tag: "option",
                        value: "Typing problems",
                        "cf-label": "Typing problems",
                    },
                    {
                        tag: "option",
                        value: "Premium",
                        "cf-label": "Premium",
                    },
                    {
                        tag: "option",
                        value: "Voice typing",
                        "cf-label": "Voice typing",
                    },
                    {
                        tag: "option",
                        value: "Other problems",
                        "cf-label": "Other problems",
                    },
                ],
            },
        ];

        this.submitCallback = this.submitCallback.bind(this);
    }

    componentDidMount() {
        this.cf = ConversationalForm.startTheConversation({
            options: {
                submitCallback: this.submitCallback,
                preventAutoFocus: true,
                theme: "light",
                // hideUserInputOnNoneTextInput: true,
                userInterfaceOptions: {
                    controlElementsInAnimationDelay: 250,
                    robot: {
                        robotResponseTime: 0,
                        chainedResponseTime: 500
                    },
                    user: {
                        showThinking: false,
                        showThumb: false
                    }
                },
                flowStepCallback: (dto, success, error) => {
                    console.log("dto....", dto, success, error);

                    if (dto.tag && dto.tag.name === "problem-type") {
                        if (dto.text === "Other problems") {
                            this.cf.addTags([
                                {
                                    tag: "input",
                                    type: "text",
                                    name: "problem-description",
                                    "cf-questions":
                                        "Tell me more about your problem.",
                                },
                            ]);
                            success();
                        } else {
                            this.cf.addRobotChatResponse(
                                `Okay, we'll try to help you with ${dto.text} later.`
                            );
                            success();
                        }
                    } else if (
                        dto.tag &&
                        dto.tag.name === "problem-description"
                    ) {
                        // success();

                        // Simulate an API request and response with setTimeout.
                        setTimeout(() => {
                            this.cf.addRobotChatResponse(
                                "Okay, my brain is toast. I'll get back to you later."
                            );

                            success();
                        }, 2000);
                    } else {
                        success();
                    }
                },
            },
            tags: this.formFields,
        });
        this.elem.appendChild(this.cf.el);
    }

    submitCallback() {
        var formDataSerialized = this.cf.getFormData(true);
        console.log("Formdata, obj:", formDataSerialized);
        this.cf.addRobotChatResponse("Thanks for talking to me!");
    }

    render() {
        return (
            <div>
                <div ref={(ref) => (this.elem = ref)} />
            </div>
        );
    }
}
